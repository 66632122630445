import request from '@/utils/request'

/**
  * 获取指定渠道注册人数
  */
export function chRegisterUsersApi(data = {}) {
  return request({
    url: 'state/chRegisterUsers',
    method: 'POST',
    data
  })
}

/**
  * 报名统计
  */
export function enrollStateApi(data = {}) {
  return request({
    url: 'state/enrollState',
    method: 'POST',
    data
  })
}

/**
  * 获取付费统计数据
  */
export function queryPayStatApi(data = {}) {
  return request({
    url: 'state/queryPayStat',
    method: 'POST',
    data
  })
}

/**
  * 修改付费统计数据
  */
export function modifyPayStatApi(data = {}) {
  return request({
    url: 'state/modifyPayStat',
    method: 'POST',
    data
  })
}