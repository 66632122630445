<template>
  <div>
    <NavTitle class="mb16" title="报名统计" :showBack="false"></NavTitle>
    <div class="panel mt16">
      <el-form size="small">
        <el-form-item label="时间范围">
          <el-date-picker
            class="mr10"
            v-model="form.screenDate"
            value-format="yyyyMMdd"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="spm">
          <el-switch v-model="form.spm">
          </el-switch>
        </el-form-item>
        <el-form-item label="下载链接:">
          <el-button @click="getUrl">生成</el-button>
          <div class="bold fs18 theme-text pointer" @click="toDownload">{{downloadUrl}}</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import { enrollStateApi } from '@/api/state.js'
export default {
  name: 'EnrollStatistic',
  components: {
    NavTitle
  },
  data() {
    return {
      form: {
        spm: false,
        screenDate: ''
      },
      downloadUrl: '',
      isGetting: false
    }
  },
  methods: {
    getUrl() {
      if(this.isGetting) return
      const formData = {
        spm: this.form.spm
      }
      if(this.form.screenDate?.length > 0) {
        Object.assign(formData, {
          startDay: this.form.screenDate[0],
          endDay: this.form.screenDate[1]
        })
      }
      console.log('formData', formData)
      this.isGetting = true
      enrollStateApi(formData).then(res => {
        console.log('报名统计', res)
        this.downloadUrl = res.data
        this.isGetting = false
      }).catch(err => {
        this.isGetting = false
        this.$tips({message: err, type:'error'})
      })
    },
    toDownload() {
      window.open(this.downloadUrl, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>